import axios from "../../axios";
import { FETCH_PROPERTY_REQUEST, FETCH_FEATURED_PROPERTY_SUCCESSFULL, FETCH_PROPERTY_SUCCESSFULL, FETCH_PROPERTY_DETAIL_SUCCESSFULL, SET_FILTER_FORM } from "../constants/constant"

export const getFeaturedProperty = () => async (dispatch) => {
    try {
        dispatch({ type: FETCH_PROPERTY_REQUEST })
        let response = await axios.get('/properties/list?status=2');
        dispatch({
            type: FETCH_FEATURED_PROPERTY_SUCCESSFULL,
            payload: response.data
        });
    }
    catch (error) {
        console.log({ error })
    }
}
export const getProjectProperty = (project_id) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_PROPERTY_REQUEST })
        let response = await axios.get('/properties/list?active=1&project=' + project_id);
        dispatch({
            type: FETCH_PROPERTY_SUCCESSFULL,
            payload: response.data
        });
    }
    catch (error) {
        console.log({ error })
    }
}
export const getAllProperty = () => async (dispatch) => {
    try {
        dispatch({ type: FETCH_PROPERTY_REQUEST })
        let response = await axios.get('/properties/list?active=1');
        dispatch({
            type: FETCH_PROPERTY_SUCCESSFULL,
            payload: response.data
        });
    }
    catch (error) {
        console.log({ error })
    }
}
export const getFilteredProperty = (filterForm = {}) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_PROPERTY_REQUEST })
        let response = await axios.get('/properties/list', {
            params: {
                ...filterForm,
                project: filterForm?.project?.value ? filterForm.project.value : null,
                type: filterForm?.type?.value ? filterForm.type.value : null,
                location: filterForm?.location?.value ? filterForm.location.value : null,
                property_status: filterForm?.property_status ? filterForm.property_status : null,
                active: 1
            }
        });
        dispatch({
            type: FETCH_PROPERTY_SUCCESSFULL,
            payload: response.data
        });
    }
    catch (error) {
        console.log({ error })
    }
}

export const setFilterFormStore = (filterForm) => async (dispatch) => {
    dispatch({
        type: SET_FILTER_FORM,
        payload: filterForm
    });
}


export const getPropertyDetail = (property_id) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_PROPERTY_REQUEST })
        let response = await axios.get('/properties/list?active=1&id=' + property_id);
        dispatch({
            type: FETCH_PROPERTY_DETAIL_SUCCESSFULL,
            payload: response.data
        });
    }
    catch (error) {
        console.log({ error })
    }
}
