import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector } from 'react-redux';

const Testimonials = () => {
    const testimonialList = useSelector(state => state.testimonialState)
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 4000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };
    return (
        <div className='testimonials container'>
            <h2 className='section_heading mb-4'>
                <span>
                    Testimonials
                </span>
                <div className="heading_line"></div>
            </h2>
            <Slider {...settings}>
                {
                    testimonialList.data.map((x, i) => (
                        <div key={i}>
                            <div className="rvs">
                                <img src={"https://elbrusinfratech.com/api/file/preview?filename=" + x?.profile_src} alt={x.reviewer} className="rvimg" loading='lazy' />
                                <p className="rvp line-3-ellipsis">
                                    {x.testimonial}
                                </p>
                                <p className="rvp2">
                                    <b>{x.reviewer}</b>
                                </p>
                            </div>
                        </div>
                    ))
                }
            </Slider>
        </div>
    )
}

export default Testimonials