import axios from "../../axios";
import { FETCH_DEVELOPER_DETIAL_SUCCESSFULL, FETCH_DEVELOPER_REQUEST, FETCH_DEVELOPER_SUCCESSFULL } from "../constants/constant"

export const getDevelopersList = () => async (dispatch) => {
    try {
        dispatch({ type: FETCH_DEVELOPER_REQUEST })
        let response = await axios.get('/developers/list?active=1');
        dispatch({
            type: FETCH_DEVELOPER_SUCCESSFULL,
            payload: response.data
        });
    }
    catch (error) {
        console.log({ error })
    }
}
export const getDeveloperDetail = (developer_id, projectType) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_DEVELOPER_REQUEST })
        let response = await axios.get(`/developers/list?id=${developer_id}${projectType ? '&with_projects=1&type=' + projectType : ''}`);
        dispatch({
            type: FETCH_DEVELOPER_DETIAL_SUCCESSFULL,
            payload: response.data
        });
    }
    catch (error) {
        console.log({ error })
    }
}
