import { FETCH_CAREER_REQUEST, FETCH_CAREER_SUCCESSFULL, FETCH_SINGLE_CAREER_SUCCESSFULL } from "../constants/constant"
const initialState = {
    loading: true,
    data: [],
    detail: {}

}
export const careerReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CAREER_REQUEST:
            return { ...state, loading: true }
        case FETCH_CAREER_SUCCESSFULL:
            return { ...state, loading: false, data: action.payload }
        case FETCH_SINGLE_CAREER_SUCCESSFULL:
            return { ...state, loading: false, detail: action.payload }
        default:
            return state
    }
}