import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
    return (
        <div className="not-found-container">
            <div className="not-found-content">
                <h1 className="not-found-heading">404</h1>
                <p className="not-found-text">Oops! The page you're looking for doesn't exist.</p>
                <Link className="btn_yl mt-4" to="/">Go To Home</Link>
            </div>
        </div>
    )
}

export default NotFound