import axios from "../../axios";
import { FETCH_TESTIMONIAL_REQUEST, FETCH_TESTIMONIAL_SUCCESSFULL } from "../constants/constant"

export const getTestimonials = () => async (dispatch) => {
    try {
        dispatch({ type: FETCH_TESTIMONIAL_REQUEST })
        let response = await axios.get('/testimonial/list?active=2');
        dispatch({
            type: FETCH_TESTIMONIAL_SUCCESSFULL,
            payload: response.data
        });
    }
    catch (error) {
        console.log({ error })
    }
}
