import axios from "../axios";

export const fetchPropertyType = async () => {
    try {
        const response = await axios.get('/property-type/list');
        return response;
    } catch (error) {
        throw error;
    }
};

export const fetchProject = async () => {
    try {
        let response = await axios.get('/developers/project?active=1');
        return response;
    } catch (error) {
        throw error;
    }
};

export const fetchLocation = async () => {
    try {
        let response = await axios.get('/location/list');
        return response;
    } catch (error) {
        throw error;
    }
};