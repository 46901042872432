import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment';

const Newscard = ({ data }) => {
    return (
        <div>
            <Link to={`/news/${data.slug}`} className="nws_box">
                <img src={"https://elbrusinfratech.com/api/file/preview?filename=" + data?.img_src} alt={data.title} loading='lazy' />
                <div className="nws_cnt">
                    <h2 className='line-2-ellipsis'>{data.title}</h2>
                    <p className='line-3-ellipsis'>
                        {data.short_content}
                    </p>
                    <p className="dte">
                        {
                            moment(data.created_at).format('MMMM DD, YYYY')
                        }
                    </p>
                </div>
            </Link>
        </div>
    )
}

export default Newscard