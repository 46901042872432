import React, { useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import Card from '../common/Card'
import ListBanner from '../components/ListBanner'
import { useDispatch, useSelector } from 'react-redux'
import { getAllProperty, getProjectProperty, getFilteredProperty } from '../redux/actions/propertyAction'
import { useState } from 'react'
import { getLocationList } from '../redux/actions/locationAtion'
import { getProjectList } from '../redux/actions/projectAction'
import { getPropertyTypeList } from '../redux/actions/propertyTypeAtion'
import { FormGroup, FormLabel } from 'react-bootstrap'
import Select from 'react-select';
import { fetchProject, fetchLocation, fetchPropertyType } from '../api/listData'
import nodatafound from "../images/no_data_found.jpg"
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

const Listing = () => {
    const [loading, setLoading] = useState(true),
        propertyStore = useSelector(state => state.property),
        locationStore = useSelector(state => state.locationList),
        projectStore = useSelector(state => state.projectList),
        propertyTypeStore = useSelector(state => state.propertyTypeList),
        [searchParams] = useSearchParams()
    const defaultFilterForm = {
        min_price: 0,
        max_price: 0,
        type: null,
        location: null,
        min_area: 0,
        max_area: 0,
        project: null,
    }
    const [filterForm, setFilterForm] = useState(defaultFilterForm)
    const dispatch = useDispatch();
    const fetchData = async (LoadValue) => {
        setLoading(true);
        if (LoadValue) {
            await dispatch(getFilteredProperty(LoadValue))
        } else {
            await dispatch(getFilteredProperty(filterForm))
        }
        setLoading(false);
    }

    const fetchDataAndSetFilters = async () => {
        try {
            let updatedFilterForm = { ...filterForm }; // Copy the current filterForm

            if (searchParams.get("type_id")) {
                if (!propertyTypeStore.data.length) {
                    await dispatch(getPropertyTypeList());
                    let fetchPropertyTypeResp = await fetchPropertyType();
                    let selected_type = fetchPropertyTypeResp.data.find(x => x.id == searchParams.get("type_id"));
                    if (selected_type) {
                        updatedFilterForm.type = { value: selected_type.id, label: selected_type.name };
                    }
                } else {
                    let selected_type = propertyTypeStore.data.find(x => x.id == searchParams.get("type_id"));
                    if (selected_type) {
                        updatedFilterForm.type = { value: selected_type.id, label: selected_type.name };
                    }
                }
            }
            if (searchParams.get("projectId")) {
                if (!projectStore.data.length) {
                    await dispatch(getProjectList())
                    let fetchProjectResp = await fetchProject()
                    let selected_project = fetchProjectResp.data.find(x => x.id == searchParams.get("projectId"))
                    if (selected_project) {
                        updatedFilterForm.project = { value: selected_project.id, label: selected_project.name }
                    }
                } else {
                    let selected_project = projectStore.data.find(x => x.id == searchParams.get("projectId"))
                    if (selected_project) {
                        updatedFilterForm.project = { value: selected_project.id, label: selected_project.name }
                    }
                }
            }

            if (searchParams.get("locationId")) {
                if (!locationStore.data.length) {
                    await dispatch(getLocationList())
                    let fetchLocationResp = await fetchLocation();
                    let selected_location = fetchLocationResp.data.find(x => x.id == searchParams.get("locationId"))
                    if (selected_location) {
                        updatedFilterForm.location = { value: selected_location.id, label: selected_location.name }
                    }
                } else {
                    let selected_location = locationStore.data.find(x => x.id == searchParams.get("locationId"))
                    if (selected_location) {
                        updatedFilterForm.location = { value: selected_location.id, label: selected_location.name }
                    }
                }
            }
            if (searchParams.get("status")) {
                updatedFilterForm.property_status = searchParams.get("status")
            }
            setFilterForm(updatedFilterForm); // Set the updated filterForm
            await fetchData(updatedFilterForm); // Fetch data with updated filterForm
        } catch (error) {
            console.error("Error fetching data and setting filters:", error);
        }
    };
    useEffect(() => {
        fetchDataAndSetFilters();

    }, [searchParams])
    const filterData = () => {
        dispatch(getFilteredProperty(filterForm))
    }

    const selectCustomStyle = {
        control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? '#f15c5e' : '#dee2e6',
            boxShadow: state.isFocused ? '#f15c5e' : '#dee2e6',
            '&:hover': {
                borderColor: state.isFocused ? '#f15c5e' : '#dee2e6',
                boxShadow: state.isFocused ? '#f15c5e' : '#dee2e6',
            },
        })
    }

    return (
        <>
            {
                loading ? (
                    <div className="loader_wrapper">
                        <div id="loader"></div>
                    </div>
                ) : (

                    <div className='listing'>
                        <ListBanner pagename="Properties" />
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-md-12 col-sm-12 mt-4">
                                    <div className="rytlist">
                                        <h3>Advanced Search</h3>
                                        <FormGroup className='mb-2 custom_form_group w-100'>
                                            <FormLabel className='mb-1'>Price Range*</FormLabel>
                                            <RangeSlider className="range_slider_adv"
                                                min={0}
                                                max={9999999}
                                                step={10000}
                                                defaultValue={[0, 0]}
                                                onInput={(e) => {
                                                    setFilterForm({ ...filterForm, min_price: e[0], max_price: e[1] })
                                                }}
                                            />
                                            <div className="d-flex align-items-center gap-1">
                                                <input className='mt-0 prin' type="text" readOnly disabled value={filterForm.min_price} placeholder='Min. Range' />
                                                <span>-</span>
                                                <input type="text" className='mt-0 prin' readOnly disabled value={filterForm.max_price} onChange={e => setFilterForm({ ...filterForm, max_price: e.target.value })} placeholder='Max. Range' />
                                            </div>
                                        </FormGroup>
                                        <FormGroup className='mb-2 custom_form_group w-100'>
                                            <FormLabel className='mb-1'>Location*</FormLabel>
                                            <div className="select_picker flex-grow-1">
                                                <Select
                                                    defaultValue={null}
                                                    isSearchable
                                                    placeholder={"Select Locaton"}
                                                    isClearable={false}
                                                    value={filterForm.location}
                                                    name="selectedLocation"
                                                    onChange={e => setFilterForm({ ...filterForm, location: e })}
                                                    styles={selectCustomStyle}
                                                    options={locationStore.data.map(item => ({ label: item.name, value: item.id }))}
                                                    menuPortalTarget={document.body}

                                                />
                                            </div>
                                        </FormGroup>
                                        <FormGroup className='mb-2 custom_form_group  ms-lg-1 w-100'>
                                            <FormLabel className='mb-1'>Project</FormLabel>
                                            <div className="select_picker flex-grow-1">
                                                <Select
                                                    defaultValue={null}
                                                    placeholder="Select Project"
                                                    isSearchable
                                                    name="selectedProject"
                                                    value={filterForm.project}
                                                    isClearable={false}
                                                    onChange={e => setFilterForm({ ...filterForm, project: e })}
                                                    styles={selectCustomStyle}
                                                    options={projectStore.data.map(item => ({ label: item.name, value: item.id }))}
                                                    id="disable-close-on-select"
                                                    menuPortalTarget={document.body}
                                                />
                                            </div>
                                        </FormGroup>
                                        <FormGroup className='mb-2 custom_form_group  ms-lg-1 w-100'>
                                            <FormLabel className='mb-1'>Type</FormLabel>
                                            <div className="select_picker flex-grow-1">
                                                <Select
                                                    defaultValue={null}
                                                    placeholder="Select Type"
                                                    isSearchable
                                                    name="selectedProject"
                                                    value={filterForm.type}
                                                    isClearable={false}
                                                    onChange={e => setFilterForm({ ...filterForm, type: e })}
                                                    styles={selectCustomStyle}
                                                    options={propertyTypeStore.data.map(item => ({ label: item.name, value: item.id }))}
                                                    id="disable-close-on-select"
                                                    menuPortalTarget={document.body}
                                                />
                                            </div>
                                        </FormGroup>
                                        <FormGroup className='mb-2 custom_form_group  ms-lg-1 w-100'>
                                            <FormLabel className='mb-1'>Area (Sq. ft)</FormLabel>
                                            <RangeSlider className="range_slider_adv"
                                                min={0}
                                                max={999999}
                                                step={1}
                                                defaultValue={[0, 0]}
                                                onInput={(e) => {
                                                    setFilterForm({ ...filterForm, min_area: e[0], max_area: e[1] })
                                                }}
                                            />
                                            <div className="d-flex align-items-center gap-1">

                                                <input className='mt-0 prin' readOnly disabled value={filterForm.min_area} type="text" placeholder='Min. Sqft' />
                                                <span>-</span>
                                                <input className='mt-0 prin' readOnly disabled value={filterForm.max_area} type="text" placeholder='Max. Sqft' />
                                            </div>
                                        </FormGroup>
                                        <button className='btn_yl' onClick={filterData}>Search</button>
                                    </div>
                                </div>
                                <div className="col-lg-9 col-md-12 col-sm-12 mt-4">
                                    <div className='d-flex flex-wrap lft_cards'>
                                        <div className="row g-3 w-100">
                                            {
                                                propertyStore.loading ? (
                                                    <div className="loader_wrapper rm_bg_primary_loader">
                                                        <div id="loader"></div>
                                                    </div>
                                                ) : (
                                                    propertyStore.data.length == 0 ? (
                                                        <div className='nodatafound'>
                                                            <h2>No Data Found</h2>
                                                            <img src={nodatafound} alt="" />
                                                        </div>
                                                    ) : (
                                                        propertyStore.data.map((x, i) => (
                                                            <div className="col-lg-4 col-md-6 col-12" key={i}>
                                                                <Card data={x} />
                                                            </div>
                                                        ))
                                                    )
                                                )
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default Listing