import { SHOW_MODAL, HIDE_MODAL } from "../constants/constant"
const initialState = {
    show: false

}
export const queryFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_MODAL:
            return { ...state, show: true }
        case HIDE_MODAL:
            return { ...state, show: false }
        default:
            return state
    }
}