import React, { useEffect } from 'react'
import ListBanner from '../components/ListBanner'
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { getCareerList } from '../redux/actions/careerAction';
import { useState } from 'react';

const Careers = () => {
    const [loading, setLoading] = useState(true)
    const careerState = useSelector(state => state.careerState)
    const dispatch = useDispatch();
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await dispatch(getCareerList())
            setLoading(false);
        }
        fetchData()
    }, [])
    return (
        <>
            {
                loading ? (
                    <div className="loader_wrapper">
                        <div id="loader"></div>
                    </div>
                ) : (
                    <div className='careers'>
                        <ListBanner pagename="Careers" />
                        <div className="container career">
                            <div className="jobs_accordian my-5">
                                <Accordion>

                                    {
                                        careerState.data.map((x, i) => (
                                            <Accordion.Item eventKey={i} key={i}>
                                                <Accordion.Header>
                                                    <div className="d-flex align-items-center justify-content-between w-100">
                                                        <div className="left_side d-flex align-items-center">
                                                            <i className="fas fa-plus add-img"></i>
                                                            <i className="fas fa-minus minus-img"></i>
                                                            <span>
                                                                {x.title}
                                                            </span>
                                                        </div>
                                                        <div className="right_side">
                                                            <Link to="/careers/1" className='btn_bg_none'>Apply Now</Link>
                                                        </div>
                                                    </div>

                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <h3 className='primary_color fs-14 mb-4 fw-4'>
                                                        JOB DESCRIPTION:
                                                    </h3>
                                                    <div dangerouslySetInnerHTML={{
                                                        __html: x?.job_desc
                                                    }}></div>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                        ))
                                    }
                                </Accordion>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default Careers