import { FETCH_TESTIMONIAL_SUCCESSFULL, FETCH_TESTIMONIAL_REQUEST } from "../constants/constant"
const initialState = {
    loading: true,
    data: []

}
export const testimonialReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TESTIMONIAL_REQUEST:
            return { ...state, loading: true }
        case FETCH_TESTIMONIAL_SUCCESSFULL:
            return { ...state, loading: false, data: action.payload }

        default:
            return state
    }
}