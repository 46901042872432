import axios from "../../axios";
import { FETCH_CAREER_REQUEST, FETCH_CAREER_SUCCESSFULL, FETCH_SINGLE_CAREER_SUCCESSFULL } from "../constants/constant"

export const getCareerList = () => async (dispatch) => {
    try {
        dispatch({ type: FETCH_CAREER_REQUEST })
        let response = await axios.get('/career/list?active=1');
        dispatch({
            type: FETCH_CAREER_SUCCESSFULL,
            payload: response.data
        });
    }
    catch (error) {
        console.log({ error })
    }
}
export const getCareerDetail = (career_id) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_CAREER_REQUEST })
        let response = await axios.get('/career/list?id=' + career_id);
        if(response.data?.id){
            dispatch({
                type: FETCH_SINGLE_CAREER_SUCCESSFULL,
                payload: response.data
            });
        }else{
            window.location = '/not-found'
        }
    }
    catch (error) {
        console.log({ error })
    }
}
