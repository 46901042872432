import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDeveloperDetail } from '../redux/actions/developerAction'
import { Link, useParams } from 'react-router-dom'
import Featuredproperties from '../components/Featuredproperties'

const DeveloperDetail = () => {
    const dispatch = useDispatch(),
        { developerDetail } = useSelector(state => state.developersState)
    const { developerId } = useParams()
    useEffect(() => {
        dispatch(getDeveloperDetail(developerId))
    }, [dispatch, developerId])
    return (
        <div className="developer_detail mt-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-12 mt-4">
                        <div className="developer_img_bog d-flex align-items-center justify-content-center h-100">
                            <img src={"https://elbrusinfratech.com/api/file/preview?filename=" + developerDetail?.img_src} alt={developerDetail?.title} className='developer_img' loading='lazy' />
                            {/* <img src={"/images/developer.png"} alt="" /> */}
                        </div>
                    </div>
                    <div className="col-lg-9 col-md-8 col-sm-12 mt-4">
                        <h1>{developerDetail?.title}</h1>
                        <p dangerouslySetInnerHTML={{
                            __html: developerDetail?.main_content
                        }}>
                        </p>
                        <div className="d-flex flex-wrap gap-3">
                            <button to="/" className='btn_yl'>
                                <i className="fab fa-whatsapp"></i> Whatsapp</button>
                            <button className='btn_yl'>
                                <i className='fad fa-phone'></i> Call
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row py-5">
                    <div className="col">
                        <h2 class="section_heading">
                            <span>Project Type</span>
                            <div class="heading_line"></div>
                        </h2>
                        <div className="d-flex gap-3">
                            <Link className="btn_yl text-light" to="/developer/1/residential">RESIDENTIAL PROJECTS</Link>
                            <Link className="btn_yl text-light" to="/developer/1/commercial">COMMERCIAL PROJECTS</Link>
                        </div>
                    </div>
                </div>
            </div>
            <Featuredproperties />
        </div>
    )
}

export default DeveloperDetail