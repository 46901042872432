import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from '../common/Card'
import { useSelector } from 'react-redux';

const Relatedproperties = () => {
    const propertyList = useSelector(state => state.property)
    const settingsrelated = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };
    return (
        <div className='featured_prop related'>
            <h2 className='section_heading mb-4'>
                <span>
                    Related Properties
                </span>
                <div className="heading_line"></div>
            </h2>
            <div className="sld">
                <Slider {...settingsrelated} pause-on-hover="false">
                    {
                        propertyList.featured.map((x, i) => (
                            <div>
                                <Card data={x} key={i} />
                            </div>
                        ))
                    }
                </Slider>
            </div>
        </div>
    )
}

export default Relatedproperties