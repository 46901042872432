import React, { useEffect } from 'react'
import ListBanner from '../components/ListBanner'
import Testimonials from '../common/Testimonials'
import Teams from '../components/Teams'
import { Link } from 'react-router-dom'
import Choose from '../components/Choose'
import CompleteTeam from '../components/CompleteTeam'
import { useDispatch, useSelector } from 'react-redux'
import { getAboutContent } from '../redux/actions/aboutContentAction'
import { getTestimonials } from '../redux/actions/testimonialsAction'
import { getAllMembers } from '../redux/actions/membersAction'
import { useState } from 'react'

const AboutUs = () => {
    const [loading, setLoading] = useState(true)
    const testimonialList = useSelector(state => state.testimonialState)
    const memberState = useSelector(state => state.memberState)
    const aboutContentStore = useSelector(state => state.aboutContent)
    const dispatch = useDispatch()
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            if (!aboutContentStore.data?.title) {
                await dispatch(getAboutContent())
            }
            if (!testimonialList.data?.main_image_src) {
                await dispatch(getTestimonials())
            }
            if (!memberState.data?.main_image_src) {
                await dispatch(getAllMembers())
            }
            setLoading(false);
        }
        fetchData()
    }, [])

    return (
        <>
            {
                loading ? (
                    <div className="loader_wrapper">
                        <div id="loader"></div>
                    </div>
                ) : (
                    <div className='about'>
                        <ListBanner pagename="About Us" />
                        <div className="container abt_main">
                            <div className="row serv">
                                <div className="col-12 mt-4">
                                    <div className='abt-cnt-main'>
                                        <img src={"https://elbrusinfratech.com/api/file/preview?filename=" + aboutContentStore.data?.main_image_src} alt="" loading='lazy' />
                                        <div>
                                            <h1>{aboutContentStore.data?.title}</h1>
                                            <p dangerouslySetInnerHTML={{
                                                __html: aboutContentStore.data?.main_text
                                            }}>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Choose />
                        <Testimonials />
                        <CompleteTeam />
                    </div>
                )
            }
        </>
    )
}

export default AboutUs