import React from 'react'
import { useSelector } from 'react-redux';
import MemberCard from '../common/MemberCard';

const Teams = () => {
    const memberStore = useSelector(state => state.memberState)

    return (
        <div className='team container'>
            <h2 className='section_heading mb-4'>
                <span>
                    Team
                </span>
                <div className="heading_line"></div>
            </h2>

            <div className="row">
                {
                    memberStore.featured.map((item, i) => (
                        <MemberCard data={item} key={i} />
                    ))
                }
            </div>

        </div>
    )
}

export default Teams