import React from 'react'
import MemberCard from '../common/MemberCard'
import { useSelector } from 'react-redux'

const CompleteTeam = () => {
    const memberState = useSelector(state => state.memberState)
    return (
        <div className='team container'>
            <h2 className='section_heading mb-4'>
                <span>
                    Team
                </span>
                <div className="heading_line"></div>
            </h2>
            <div className="row">
                {
                    memberState.data.map((x, i) => (
                        <MemberCard data={x} key={i} />
                    ))
                }
            </div>
        </div>
    )
}

export default CompleteTeam