import axios from "../../axios";
import { FETCH_PROPERTY_TYPE_REQUEST, FETCH_PROPERTY_TYPE_SUCCESSFULL } from "../constants/constant"

export const getPropertyTypeList = () => async (dispatch) => {
    try {
        dispatch({ type: FETCH_PROPERTY_TYPE_REQUEST })
        let response = await axios.get('/property-type/list');
        dispatch({
            type: FETCH_PROPERTY_TYPE_SUCCESSFULL,
            payload: response.data
        });
    }
    catch (error) {
        console.log({ error })
    }
}
