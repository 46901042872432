import React from 'react'
import { Link } from 'react-router-dom'

const ListBanner = ({ pagename, developer }) => {
    return (
        <div className="list_banner">
            <h1>{pagename}</h1>
            {
                developer?.length &&
                <p>
                    BY : {developer}
                </p>
            }

            {/* <div className="d-flex flex-wrap gap-2 justify-content-center">
                <Link to="/">HOME</Link>
                <span>></span>
                <div className="act">{pagename}</div>
            </div> */}
        </div>
    )
}

export default ListBanner