import { FETCH_FEATURED_MEMBER_SUCCESSFULL, FETCH_MEMBER_REQUEST, FETCH_MEMBER_SUCCESSFULL } from "../constants/constant"
const initialState = {
    loading: true,
    data: [],
    featured: [],

}
export const membersReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MEMBER_REQUEST:
            return { ...state, loading: true }
        case FETCH_MEMBER_SUCCESSFULL:
            return { ...state, loading: false, data: action.payload }
        case FETCH_FEATURED_MEMBER_SUCCESSFULL:
            return { ...state, loading: false, featured: action.payload }
        default:
            return state
    }
}