import axios from "../../axios";
import { FETCH_LOCATION_REQUEST, FETCH_LOCATION_SUCCESSFULL } from "../constants/constant"

export const getLocationList = () => async (dispatch) => {
    try {
        dispatch({ type: FETCH_LOCATION_REQUEST })
        let response = await axios.get('/location/list');
        dispatch({
            type: FETCH_LOCATION_SUCCESSFULL,
            payload: response.data
        });
    }
    catch (error) {
        console.log({ error })
    }
}
