import React, { useEffect, useRef, useState } from 'react'
import ListBanner from '../components/ListBanner'
import { useParams } from 'react-router-dom'
import { getCareerDetail } from '../redux/actions/careerAction'
import { useDispatch, useSelector } from 'react-redux'
import axios from '../axios'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CareerDetail = () => {
    const { id } = useParams()
    const fileInputRef = useRef(null);
    const [loading, setLoading] = useState(true)

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        contact: '',
        linked_in: '',
        education: '',
        experience: ''
    })
    const [resumeSrc, setResumeSrc] = useState(null)
    const careerState = useSelector(state => state.careerState)
    const dispatch = useDispatch();
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await dispatch(getCareerDetail(id))
            setLoading(false);
        }
        fetchData()
    }, [id])


    const handleFileChange = (e) => {
        console.log({ file: e.target.files })
        const file = e.target.files[0];
        setResumeSrc(file);
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log({ formData, resumeSrc })
        const formDataToSend = new FormData();
        formDataToSend.append('resume_src', resumeSrc);
        formDataToSend.append('first_name', formData.first_name);
        formDataToSend.append('last_name', formData.last_name);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('contact', formData.contact);
        formDataToSend.append('linked_in', formData.linked_in);
        formDataToSend.append('eduation', formData.education);
        formDataToSend.append('career_id', id);
        formDataToSend.append('experience', formData.experience);
        axios.post('/application/create', formDataToSend, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then(resp => {
            setFormData({
                first_name: '',
                last_name: '',
                email: '',
                contact: '',
                linked_in: '',
                education: '',
                experience: ''
            })
            setResumeSrc(null)
            toast.success('Form Submit')
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }).catch(err => (
            toast.error('There is an error')

        ))
    }

    return (
        <>
            {
                loading ? (
                    <div className="loader_wrapper">
                        <div id="loader"></div>
                    </div>
                ) : (
                    <div className="career_detail">
                        <ListBanner pagename={careerState.detail?.title} />
                        <div className="container my-5">
                            <h2 className='section_heading'>Apply Online</h2>
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-12 col-lg-6 col-md-6">
                                        <input type="text" value={formData.first_name} onChange={e => setFormData({ ...formData, first_name: e.target.value })} placeholder='First Name*' required />
                                    </div>
                                    <div className="col-12 col-lg-6 col-md-6">
                                        <input type="text" value={formData.last_name} onChange={e => setFormData({ ...formData, last_name: e.target.value })} placeholder='Last Name*' required />
                                    </div>
                                    <div className="col-12 col-lg-6 col-md-6">
                                        <input type="email" value={formData.email} onChange={e => setFormData({ ...formData, email: e.target.value })} placeholder='Email*' required />
                                    </div>
                                    <div className="col-12 col-lg-6 col-md-6">
                                        <input type="text" value={formData.contact} onChange={e => setFormData({ ...formData, contact: e.target.value })} placeholder='Phone*' required />
                                    </div>
                                    <div className="col-12 col-lg-12 col-md-12">
                                        <input type="text" value={formData.linked_in} onChange={e => setFormData({ ...formData, linked_in: e.target.value })} placeholder='LinkedIn Profile Link*' required />
                                    </div>
                                    <div className="col-12 col-lg-6 col-md-6">
                                        <input type="text" value={formData.education} onChange={e => setFormData({ ...formData, education: e.target.value })} placeholder='Education*' required />
                                    </div>
                                    <div className="col-12 col-lg-6 col-md-6">
                                        <input type="text" value={formData.experience} onChange={e => setFormData({ ...formData, experience: e.target.value })} placeholder='Experience*' required />
                                    </div>
                                    <div className="col-12 col-lg-12 col-md-12">
                                        <input type="File" ref={fileInputRef} accept=".pdf,.doc,.docx" onChange={handleFileChange} required />
                                    </div>
                                    <div className="col-12 col-lg-12 col-md-12 mt-4 text-center">
                                        <button className='btn_yl' type='submit'>Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default CareerDetail