import React from 'react'

const MemberCard = ({ data }) => {
    return (
        <div className="col-lg-3 col-md-6 col-sm-12 mt-4">
            <div className="team_box">
                <img src={"https://elbrusinfratech.com/api/file/preview?filename=" + data?.profile_image} alt={data?.username} loading='lazy' />
                <div className='tm-cnt'>
                    <h4>{data?.username}</h4>
                    <h5>{data.designation?.name}</h5>
                    <span>
                        {data?.email}
                    </span>
                </div>
                {/* <div className="scbx">
                    <a href=""><i className='fab fa-facebook-f'></i></a>
                    <a href=""><i className='fab fa-instagram'></i></a>
                    <a href=""><i className='fab fa-twitter'></i></a>
                </div> */}
            </div>
        </div>
    )
}

export default MemberCard