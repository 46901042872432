import axios from "../../axios";
import { FETCH_NEWS_REQUEST, FETCH_LATEST_NEWS_SUCCESSFULL, FETCH_NEWS_SUCCESSFULL, FETCH_SINGLE_NEWS_SUCCESSFULL } from "../constants/constant"

export const getLatestNews = () => async (dispatch) => {
    try {
        dispatch({ type: FETCH_NEWS_REQUEST })
        let response = await axios.get('/latest-news/list?latest=1');
        dispatch({
            type: FETCH_LATEST_NEWS_SUCCESSFULL,
            payload: response.data
        });
    }
    catch (error) {
        console.log({ error })
    }
}
export const getAllNews = () => async (dispatch) => {
    try {
        dispatch({ type: FETCH_NEWS_REQUEST })
        let response = await axios.get('/latest-news/list?active=1');
        dispatch({
            type: FETCH_NEWS_SUCCESSFULL,
            payload: response.data
        });
    }
    catch (error) {
        console.log({ error })
    }
}
export const getSingleDetail = (news_slug) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_NEWS_REQUEST })
        let response = await axios.get('/latest-news/list?slug=' + news_slug);
        dispatch({
            type: FETCH_SINGLE_NEWS_SUCCESSFULL,
            payload: response.data
        });
    }
    catch (error) {
        console.log({ error })
    }
}
