import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';

const Card = ({ data }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplaySpeed: 2000,
        fade: true
    };
    console.log({ data })
    return (
        <div className='card_box'>
            <div className="slider_box">
                <Link to={`/detail/${data.id}`} className="slid">
                    <Slider {...settings}>
                        {
                            data?.property_images ? (
                                data?.property_images.map((x, i) => (
                                    <div key={i}>
                                        <img src={"https://elbrusinfratech.com/api/file/preview?filename=" + x.image_src} className='card_img' loading='lazy' />
                                    </div>
                                ))
                            ) : (
                                <div>
                                    <img src='./images/card2.jpg' className='card_img' loading='lazy' />
                                </div>
                            )
                        }
                    </Slider>
                </Link>

                <div className={`sell ${data.property_status == 1 ? "info_badge" : data.property_status == 2 ? "sec_badge" : data.property_status == 3 ? "ter_badge" : "primary_badge"}`}>
                    {
                        data.property_status == 1 ? 'Upcoming' : (data.property_status == 2 ? 'Under Construction' : (data.property_status == 3 ? 'Ready To Move' : 'Buy'))
                    }
                </div>
                {/* <div className="select_box">
                    <button><i className="far fa-star"></i></button>
                    <button><i className="fas fa-plus"></i></button>
                </div> */}
            </div>
            <div className="content_box">
                <div className="tags">
                    <i className="fas fa-location-dot"></i>
                    <div className='card_lower'>{data.developer.title}</div>
                    {/* <span>-</span>
                    <div className='card_lower'>Brooklyn</div> */}
                </div>
                <Link to={`/detail/${data.id}`} className='name'>{data.name}</Link>
                <p className='light_text' dangerouslySetInnerHTML={{
                    __html: data.features
                }}></p>
                <div className="price_box">
                    <h3>{data.price} <span>INR</span></h3>
                    <div className="haves light_text">
                        <div>
                            <i className="fal primary_color fa-chart-area"></i>
                            <span>{data.size} sq.ft</span>
                        </div>
                        <div>
                            <i className="fal primary_color fa-bed"></i>
                            <span>{data.bedrooms}</span>
                        </div>
                        <div>
                            <i className="fal primary_color fa-bath"></i>
                            <span>{data.bathrooms}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Card