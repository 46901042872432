import React, { useEffect } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Newscard from '../components/Newscard';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getLatestNews, getSingleDetail } from '../redux/actions/newsAction';
import moment from 'moment';
import { useState } from 'react';

const NewsInner = () => {
    const { articleSlug } = useParams()
    const [loading, setLoading] = useState(true)
    const newsStore = useSelector(state => state.newsState)
    const dispatch = useDispatch();
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await dispatch(getLatestNews())
            await dispatch(getSingleDetail(articleSlug))
            setLoading(false);
        }
        fetchData()
    }, [articleSlug, dispatch])
    const settingsrelated = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };
    return (
        <>
            {
                loading ? (
                    <div className="loader_wrapper">
                        <div id="loader"></div>
                    </div>
                ) : (
                    <div className='news_inner'>
                        <div className="container">
                            <div className="nws_main">
                                <img src={"https://elbrusinfratech.com/api/file/preview?filename=" + newsStore.detail?.img_src} alt="" loading='lazy' />
                                <h2>
                                    {newsStore.detail?.title}
                                </h2>
                                <span className="dte">{moment(newsStore.detail?.created_at).format('MMMM DD, YYYY')}</span>
                                <p dangerouslySetInnerHTML={{
                                    __html: newsStore.detail?.main_content
                                }}></p>
                                <a className="dntebtn btn_yl mt-5 m-auto d-block" target='_blank' rel="noreferrer" href={newsStore.detail?.external_link}>Read More <i className="ms-2 fad fa-external-link"></i> </a>
                            </div>
                            <div className="sld_news">
                                <h2 className='section_heading'>
                                    <span>
                                        Latest News
                                    </span>
                                    <div className="heading_line"></div>

                                </h2>
                                <div className="sld">
                                    <Slider {...settingsrelated}>
                                        {
                                            newsStore.latest.map((item, i) => (
                                                <div key={i}>
                                                    <Newscard data={item} />
                                                </div>
                                            ))
                                        }
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default NewsInner