import React, { useState } from 'react'
import ListBanner from '../components/ListBanner'
import axios from '../axios'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyDetails } from '../redux/actions/companyDetailsAction';

const ContactUs = () => {
  const dispatch = useDispatch();
  const companyDetailsStore = useSelector(state => state.companyDetails)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact: '',
    message: ''
  })
  useEffect(() => {
    if (!companyDetailsStore.data?.company_name) {
      dispatch(getCompanyDetails())
    }
  }, [dispatch])
  const handleSubmit = (e) => {
    e.preventDefault()
    console.log({ formData })
    axios.post('/contact/create', formData)
    setFormData({
      name: '',
      email: '',
      contact: '',
      message: ''
    })
    toast.success('Form Submit')

  }
  return (
    <>
      <div className='contact'>
        <ListBanner pagename="Contact Us" />
        <div className='container'>
          <div className="row pb-5 pb-md-0">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="lft_cnt">
                <h2 className='section_heading mb-4'>
                  <span>
                    Get In Touch
                  </span>
                  <div className="heading_line"></div>
                </h2>
                <p>{companyDetailsStore.data.contact_page_text}</p>
                <div className="lcs">
                  <span>
                    <i className='fad fa-map-marker-alt'></i>
                  </span>
                  <div>
                    <h5>
                      {companyDetailsStore.data.company_name}
                    </h5>
                    {companyDetailsStore.data.address}
                  </div>
                </div>
                <div className="lcs">
                  <span>
                    <i className='fad fa-phone'></i>
                  </span>
                  <a href={"tel:" + companyDetailsStore.data.company_contact_1}>
                    {companyDetailsStore.data.company_contact_1}
                  </a>
                </div>
                {
                  companyDetailsStore.data?.company_contact_2 &&
                  <div className="lcs">
                    <span>
                      <i className='fad fa-phone'></i>
                    </span>
                    <a href={"tel:" + companyDetailsStore.data.company_contact_2}>
                      {companyDetailsStore.data.company_contact_2}
                    </a>
                  </div>
                }
                <div className="lcs">
                  <span>
                    <i className='fal fa-envelope'></i>
                  </span>
                  <a href={"mailto:" + companyDetailsStore.data.company_email}>
                    {companyDetailsStore.data.company_email}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="cnt_frm">
                <h2 className='section_heading mb-4'>
                  <span>
                    Contact Us Today
                  </span>
                  <div className="heading_line"></div>
                </h2>
                <form onSubmit={handleSubmit}>
                  <input type="text" value={formData.name} onChange={e => setFormData({ ...formData, name: e.target.value })} placeholder='Enter Your Name*' required />
                  <input type="email" value={formData.email} onChange={e => setFormData({ ...formData, email: e.target.value })} placeholder='Enter Your Email Address*' required />
                  <input type="text" value={formData.contact} onChange={e => setFormData({ ...formData, contact: e.target.value })} placeholder='Enter Your Phone Number' required />
                  <textarea name="" id="" value={formData.message} onChange={e => setFormData({ ...formData, message: e.target.value })} placeholder='Message' required></textarea>
                  <button className="btn_yl" type='submit'>Send Message</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactUs