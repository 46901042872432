import React, { useEffect } from 'react'
import Banner from '../components/Banner'
import Browseproperties from '../components/Browseproperties'
import Featuredproperties from '../components/Featuredproperties'
import Premiumdesign from '../components/Premiumdesign'
import Testimonials from '../common/Testimonials'
import Teams from '../components/Teams'
import NewsHome from '../common/NewsHome'
import Cta from '../components/Cta'
import Counter from '../components/Counter'
import { useDispatch, useSelector } from 'react-redux'
import { getHomeContent } from '../redux/actions/homeContentAction'
import { getFeaturedProperty } from '../redux/actions/propertyAction'
import { getLatestNews } from '../redux/actions/newsAction'
import { getFeaturedMembers } from '../redux/actions/membersAction'
import { getTestimonials } from '../redux/actions/testimonialsAction'
import { useState } from 'react'
import RealEstateDevelopers from '../common/RealEstateDevelopers'
import { getDevelopersList } from '../redux/actions/developerAction'
import { getAboutContent } from '../redux/actions/aboutContentAction'


const Home = () => {

    const dispatch = useDispatch()
    const homeContentStore = useSelector(state => state.homeContent)
    const propertyList = useSelector(state => state.property)
    const aboutContentStore = useSelector(state => state.aboutContent)
    const testimonialList = useSelector(state => state.testimonialState)
    const developers = useSelector(state => state.developersState)
    const memberStore = useSelector(state => state.memberState)
    const newsStore = useSelector(state => state.newsState)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            if (!homeContentStore?.data?.banner_images) {
                await dispatch(getHomeContent())
            }
            if (!propertyList.featured.length) {
                await dispatch(getFeaturedProperty())
            }
            if (!newsStore.latest.length) {
                await dispatch(getLatestNews())
            }
            if (!memberStore.featured.length) {
                await dispatch(getFeaturedMembers())
            }
            if (!testimonialList.data.length) {
                await dispatch(getTestimonials())
            }
            if (!developers.data.length) {
                await dispatch(getDevelopersList())
            }
            if (!aboutContentStore.data?.title) {
                await dispatch(getAboutContent())
            }
            setLoading(false);
        }
        try {
            fetchData()
        } catch (error) {
            console.log(error)
        }

    }, [dispatch])


    return (
        <div>
            {
                loading ? (
                    <div className="loader_wrapper">
                        <div id="loader"></div>
                    </div>
                ) : (
                    <>
                        <Banner />
                        {/* <Browseproperties /> */}
                        <Featuredproperties />
                        <Counter />
                        {/* <Premiumdesign /> */}
                        <RealEstateDevelopers />
                        <NewsHome />
                        <Teams />
                        <Testimonials />
                        <Cta />
                    </>

                )
            }
        </div>
    )
}

export default Home