import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { getCompanyDetails } from '../redux/actions/companyDetailsAction';
import { getProjectList } from '../redux/actions/projectAction';

const Footer = () => {
    const dispatch = useDispatch();
    const projectStore = useSelector(state => state.projectList)
    const companyDetailsStore = useSelector(state => state.companyDetails)
    useEffect(() => {
        dispatch(getCompanyDetails())
        if (!projectStore.data.length) {
            dispatch(getProjectList())
        }
    }, [dispatch])
    return (
        <footer>
            <div className="container">
                <div className="row py-4">
                    <div className="col-lg-3 col-md-6 col-sm-12 mt-3">
                        <Link to='/'>
                            <img src="./images/logo_1.png" alt="" loading='lazy' />
                        </Link>
                        <p className="desc">
                            {companyDetailsStore.data?.compnay_short_detail}
                        </p>
                        <div className="soc_bx">
                            <Link to='/'><i className="fab fa-facebook-f"></i></Link>
                            <Link to='/'><i className="fab fa-instagram"></i></Link>
                            <Link to='/'><i className="fab fa-twitter"></i></Link>
                            <Link to='/'><i className="fab fa-youtube"></i></Link>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 mt-3">
                        <h3>Contact us</h3>
                        <a href="javascript:;">
                            {companyDetailsStore.data?.address}
                        </a>
                        <a href={"tel:" + companyDetailsStore.data?.company_contact_1}>
                            {companyDetailsStore.data?.company_contact_1}
                        </a>
                        {
                            companyDetailsStore.data?.company_contact_2 &&
                            <a href={"tel:" + companyDetailsStore.data?.company_contact_2}>
                                {companyDetailsStore.data?.company_contact_2}
                            </a>
                        }
                        <a href={"mailto:" + companyDetailsStore.data?.company_email}>
                            {companyDetailsStore.data?.company_email}
                        </a>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 mt-3">
                        <h3>
                            Buy
                        </h3>
                        <Link to="/property?status=residential">Residential</Link>
                        <Link to="/property?status=commercial">Commercial</Link>
                        <Link to="/property?status=upcoming">Upcoming</Link>
                        <Link to="/property?status=under-construction">Under Construction</Link>
                        <Link to="/property?status=ready-to-move">Ready To Move</Link>
                        {/* {
                            projectStore.data.map((x, i) => {
                                let slug = x.name.toLowerCase().replace(/\s+/g, '-');
                                slug = slug.replace(/[^\w\-]+/g, '');
                                return (
                                    <Link key={i} to={`/property/${slug}/${x.id}`}>{x.name}</Link>
                                )
                            })
                        } */}
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 mt-3">
                        <h3>
                            Links
                        </h3>
                        <Link to="/news">
                            Latest News
                        </Link>
                        <Link to="/about">
                            About Us
                        </Link>
                        <Link to="/contact">
                            Contact Us
                        </Link>
                        <Link to="/careers">
                            Career
                        </Link>
                    </div>
                </div>
                <div className="btm">
                    <div className="lft">
                        <Link to="/">Terms of Use</Link>
                        <Link to="/">Privacy Policy</Link>
                        <Link to="/">Copyright Policy</Link>
                    </div>
                    <div className="ryt">
                        <p>© 2023 Developers, All Rights Reserved</p>
                    </div>
                </div>
            </div>
        </footer >
    )
}

export default Footer