import { FETCH_NEWS_REQUEST, FETCH_NEWS_SUCCESSFULL, FETCH_LATEST_NEWS_SUCCESSFULL, FETCH_SINGLE_NEWS_SUCCESSFULL } from "../constants/constant"
const initialState = {
    loading: true,
    data: [],
    latest: [],
    detail: {}

}
export const newsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_NEWS_REQUEST:
            return { ...state, loading: true }
        case FETCH_NEWS_SUCCESSFULL:
            return { ...state, loading: false, data: action.payload }
        case FETCH_LATEST_NEWS_SUCCESSFULL:
            return { ...state, loading: false, latest: action.payload }
        case FETCH_SINGLE_NEWS_SUCCESSFULL:
            return { ...state, loading: false, detail: action.payload }
        default:
            return state
    }
}