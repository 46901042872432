import { FETCH_HOME_CONTENT_REQUEST, FETCH_HOME_CONTENT_SUCCESSFULL } from "../constants/constant"
const initialState = {
    loading: true,
    data: {}

}
export const homeContentReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_HOME_CONTENT_REQUEST:
            return { ...state, loading: true }
        case FETCH_HOME_CONTENT_SUCCESSFULL:
            return { ...state, loading: false, data: action.payload }
        default:
            return state
    }
}