import React from 'react'
import CountUp from 'react-countup';
import { useSelector } from 'react-redux';

const Counter = () => {
    const aboutContentStore = useSelector(state => state.aboutContent)
    return (
        <div className='counter'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-12 mt-4 mb-3 mb-lg-0">
                        <div className="contr">
                            <i className="fad fa-smile"></i>
                            <h2>
                                <CountUp scrollSpyOnce={true} end={aboutContentStore.data?.happy_customer || 0} enableScrollSpy={true} />+
                            </h2>
                            <p>Happy Customer</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 mt-4 mb-3 mb-lg-0">
                        <div className="contr">
                            <i className="fad fa-chart-area"></i>
                            <h2>
                                <CountUp scrollSpyOnce={true} end={aboutContentStore.data?.area_sold || 0} enableScrollSpy={true} />+ Sq.Ft.
                            </h2>
                            <p>Area Sold</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 mt-4 mb-3 mb-lg-0">
                        <div className="contr">
                            <i className="fad fa-users"></i>
                            <h2>
                                <CountUp scrollSpyOnce={true} end={aboutContentStore.data?.years_experience || 0} enableScrollSpy={true} />+
                            </h2>
                            <p>Years of Experience</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 mt-4 mb-3 mb-lg-0">
                        <div className="contr">
                            <i className="fad fa-route"></i>
                            <h2>
                                <CountUp scrollSpyOnce={true} end={aboutContentStore.data?.channel_partners || 0} enableScrollSpy={true} />+
                            </h2>
                            <p>Channel Associates</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Counter