import React, { useEffect } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from '../common/Card'
import { useDispatch, useSelector } from 'react-redux';
import { getFeaturedProperty } from '../redux/actions/propertyAction';

const Featuredproperties = () => {
    const dispatch = useDispatch()
    const propertyList = useSelector(state => state.property)
    const settingsfeature = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    useEffect(() => {
        if (!propertyList.featured.length) {
            dispatch(getFeaturedProperty())
        }
    }, [])

    return (
        <div className='featured_prop container mt-5'>
            <h2 className='section_heading'>
                <span>
                    Featured Properties
                </span>
                <div className="heading_line"></div>
            </h2>
            <div className="sld">
                <Slider {...settingsfeature} pause-on-hover="false">
                    {
                        propertyList.featured.map((x, i) => (
                            <div key={i}>
                                <Card data={x} />
                            </div>
                        ))
                    }
                </Slider>
            </div>
        </div>
    )
}

export default Featuredproperties