import axios from "../../axios";
import { FETCH_ABOUT_CONTENT_REQUEST, FETCH_ABOUT_CONTENT_SUCCESSFULL } from "../constants/constant"

export const getAboutContent = () => async (dispatch) => {
    try {
        dispatch({ type: FETCH_ABOUT_CONTENT_REQUEST })
        let response = await axios.get('/about-us/content');
        dispatch({
            type: FETCH_ABOUT_CONTENT_SUCCESSFULL,
            payload: response.data
        });
    }
    catch (error) {
        console.log({ error })
    }
}
