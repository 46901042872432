import { FETCH_COMPANY_DETAILS_REQUEST, FETCH_COMPANY_DETAILS_SUCCESSFULL } from "../constants/constant"
const initialState = {
    loading: true,
    data: {}

}
export const companyDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_COMPANY_DETAILS_REQUEST:
            return { ...state, loading: true }
        case FETCH_COMPANY_DETAILS_SUCCESSFULL:
            return { ...state, loading: false, data: action.payload }
        default:
            return state
    }
}