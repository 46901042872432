import React, { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Relatedproperties from '../components/Relatedproperties';
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import { useDispatch, useSelector } from 'react-redux';
import { getFeaturedProperty, getPropertyDetail } from '../redux/actions/propertyAction';
import { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../axios';


const Detailpage = () => {
    const { id } = useParams(),
        navigate = useNavigate(),
        [loading, setLoading] = useState(true),
        propertyStore = useSelector(state => state.property),
        [formData, setFormData] = useState({
            name: '',
            email: '',
            contact: '',
            message: ''
        }),
        dispatch = useDispatch();
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await dispatch(getPropertyDetail(id))
            await dispatch(getFeaturedProperty())
            setLoading(false);
        }
        fetchData()
    }, [id])

    useEffect(() => {
        if (!propertyStore?.propertyDetail?.id && !loading) {
            navigate("/not-found")
        }
    }, [loading, propertyStore])


    const handleSubmit = (e) => {
        e.preventDefault()
        console.log({ formData })
        axios.post('/property-query/create', formData).then(resp => {
            setFormData({
                name: '',
                email: '',
                contact: '',
                message: ''
            })
            toast.success('Form Submit')
        })
    }
    return (
        <>
            {

                loading ? (
                    <div className="loader_wrapper">
                        <div id="loader"></div>
                    </div>
                ) : (
                    propertyStore.propertyDetail?.id ? (

                        <div className='detail_page'>
                            <div className="header">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                                        <h1>{propertyStore.propertyDetail?.name}</h1>
                                        <div className="d-flex flex-wrap align-items-center">
                                            <button><b>Developer</b></button>
                                            <div className='light_text'>{propertyStore.propertyDetail?.developer?.title}</div>
                                            {/* <div className='prp light_text'>PROPERTY ID: 56H886</div> */}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 mt-4 d-flex flex-column align-items-end">
                                        <h2>
                                            <span className='light_text fs-14 fw-4'>Price: </span>
                                            <span className='primary_color'>{propertyStore.propertyDetail?.price}</span>
                                            <span className='primary_color'> INR</span>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-8 col-md-12 mb-5 mb-lg-0 mb-md-0">
                                    <div className="custom_image_gallery mb-4">
                                        <ImageGallery items={[...propertyStore.propertyDetail?.property_images.map(x => ({ original: "https://elbrusinfratech.com/api/file/preview?filename=" + x.image_src, thumbnail: "https://elbrusinfratech.com/api/file/preview?filename=" + x.image_src }))]} />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 col-md-12 mb-5 mb-lg-0 mb-md-0">
                                    <div className="contact">
                                        <div className="d-flex flex-wrap">
                                            <button to="/" className='btn_yl'>
                                                <i className="fab fa-whatsapp"></i> Whatsapp</button>
                                            <button className='btn_yl'>
                                                <i className='fad fa-phone'></i> Call
                                            </button>
                                        </div>
                                        <div className="cnts">
                                            <form onSubmit={handleSubmit}>
                                                <input required type="text" placeholder='Name*' value={formData.name} onChange={e => setFormData({ ...formData, name: e.target.value })} />
                                                <input required type="number" placeholder='Phone Number*' value={formData.email} onChange={e => setFormData({ ...formData, email: e.target.value })} />
                                                <input required type="email" placeholder='Email' value={formData.contact} onChange={e => setFormData({ ...formData, contact: e.target.value })} />
                                                <textarea required name="" placeholder='Message' value={formData.message} onChange={e => setFormData({ ...formData, message: e.target.value })}></textarea>
                                                <button className='btn_yl' type='submit'>Send</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="det_box">
                                <div className="row">
                                    <div className="col-lg-8 col-md-12 col-sm-12 mt-4">
                                        <div className="project_literature">
                                            <div className="desc border_card p-3 mb-4">
                                                <h2>Description</h2>
                                                <p className='light_text' dangerouslySetInnerHTML={{
                                                    __html: propertyStore.propertyDetail?.description
                                                }}>
                                                </p>
                                            </div>
                                            <div className='border_card p-3 mb-4'>
                                                <h2>Property features</h2>
                                                <p className='light_text' dangerouslySetInnerHTML={{
                                                    __html: propertyStore.propertyDetail?.features
                                                }}></p>
                                            </div>
                                            <div className="prdet border_card p-3 mb-4">
                                                <h2>Property details</h2>
                                                <div className="d-flex flex-wrap mndet">
                                                    <div className='d-flex'>
                                                        <i className="fal fa-chart-area"></i>
                                                        <div className="d-flex justify-content-between gap-2">
                                                            <span>Size:</span>
                                                            <span>{propertyStore.propertyDetail?.size} sq.ft</span>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <i className="fal fa-bed"></i>

                                                        <div className="d-flex justify-content-between gap-2">
                                                            <span>Bedrooms:</span>
                                                            <span>{propertyStore.propertyDetail?.bedrooms}</span>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <i className="fal fa-bath"></i>
                                                        <div className="d-flex justify-content-between gap-2">
                                                            <span>Bathrooms:</span>
                                                            <span>{propertyStore.propertyDetail?.bathrooms}</span>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <i className="fal fa-building"></i>
                                                        <div className="d-flex justify-content-between gap-2">
                                                            <span>Floors: </span>
                                                            <span>{propertyStore.propertyDetail?.floors}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="prdet border_card p-3 mb-4">
                                                <h2>Utilities</h2>
                                                <div className="d-flex flex-wrap mndet">
                                                    {
                                                        propertyStore.propertyDetail?.property_utilities.map((x, i) => (
                                                            <div key={i} className='d-flex'>
                                                                <i className={x.utility_detail.icon ? x.utility_detail.icon : 'fal fa-burn'}></i>
                                                                <div className="d-flex justify-content-between gap-2">
                                                                    <span>{x.utility_detail.name}</span>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="videocontainer mb-4">
                                        <h2 className='section_heading mb-4'>
                                            <span>
                                                Project Video
                                            </span>
                                            <div className="heading_line"></div>

                                        </h2>
                                        <video autoPlay muted loop>
                                            <source src="./images/video1.mp4" type="video/mp4" />
                                        </video>
                                    </div> */}
                                        {
                                            propertyStore.propertyDetail?.embed_map &&
                                            <div className="location mb-4">
                                                <h2 className='section_heading mb-4'>
                                                    <span>
                                                        Geographical
                                                    </span>
                                                    <div className="heading_line"></div>

                                                </h2>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: propertyStore.propertyDetail?.embed_map,
                                                    }}
                                                />

                                            </div>
                                        }
                                    </div>
                                    <div className="col-lg-4 col-md-12 col-sm-12 mt-4">
                                        <div className="ryt mb-4">

                                            {/* <div className="useful">
                                            <h3>Useful Links</h3>
                                            <div>
                                                <Link to=''>
                                                    Property for sale in karachi
                                                </Link>
                                                <Link to=''>
                                                    Property for sale in Lahore
                                                </Link>
                                                <Link to=''>
                                                    Property for sale in Gujaranwala
                                                </Link>
                                                <Link to=''>
                                                    Property for sale in Faisalabad
                                                </Link>
                                                <Link to=''>
                                                    Property for sale in Kashmir
                                                </Link>
                                            </div>
                                        </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Relatedproperties />
                        </div>
                    ) : (
                        <p>Not found</p>
                    )

                )

            }
        </>

    )
}

export default Detailpage