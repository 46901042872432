import axios from "../../axios";
import { FETCH_COMPANY_DETAILS_REQUEST, FETCH_COMPANY_DETAILS_SUCCESSFULL } from "../constants/constant"

export const getCompanyDetails = () => async (dispatch) => {
    try {
        dispatch({ type: FETCH_COMPANY_DETAILS_REQUEST })
        let response = await axios.get('/company-detail/content');
        dispatch({
            type: FETCH_COMPANY_DETAILS_SUCCESSFULL,
            payload: response.data
        });
    }
    catch (error) {
        console.log({ error })
    }
}
