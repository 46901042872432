import React, { useRef } from 'react'

// swipper js
import { Swiper, SwiperSlide } from 'swiper/react';

// swipper css
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

const RealEstateDevelopers = () => {
    const developers = useSelector(state => state.developersState)
    const swiperRefLocal = useRef()
    const handleMouseEnter = () => {
        swiperRefLocal?.current?.swiper?.autoplay?.stop()
    };

    const handleMouseLeave = () => {
        swiperRefLocal?.current?.swiper?.autoplay?.start()
    };
    return (
        <div className="real_estate_developers">
            <div className="container">
                <h2 className='section_heading'>
                    <span>
                        Real Estate Developers
                    </span>
                    <div className="heading_line"></div>
                </h2>
            </div>
            <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={`developers_slider_container my-5 ${developers.data.length < 5 ? 'slider_centered' : ''}`}>

                <Swiper
                    spaceBetween={0}
                    slidesPerView='auto'
                    grabCursor={true}
                    ref={swiperRefLocal}
                    pagination={false}
                    modules={[Autoplay]}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                >
                    {
                        developers.data.map((x, i) => (
                            <SwiperSlide key={i}>
                                <Link to={"/developer/" + x.id} className='text-center'>
                                    <img src={"https://elbrusinfratech.com/api/file/preview?filename=" + x.img_src} alt={x.title} loading='lazy' />
                                </Link>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
        </div >
    )
}

export default RealEstateDevelopers