import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom'
import { getProjectList } from '../redux/actions/projectAction';
import { showModal } from '../redux/actions/queryFormAction';
import { Button } from 'react-bootstrap';

const TopNavbaar = () => {
    const [scroll, setscroll] = useState(false)
    const location = useLocation();
    const dispatch = useDispatch();
    const projectStore = useSelector(state => state.projectList)

    useEffect(() => {
        document.getElementById('menu-btn').checked = false
    }, [location]);
    useEffect(() => {
        dispatch(getProjectList())
        window.addEventListener("scroll", () => {
            setscroll(window.scrollY > 500)
        })
    }, [])
    return (
        // variant_2 
        <>
            <div className={location?.pathname == '/' && !scroll ? "top_navbar" : "top_navbar"}>
                <nav id="mynav" className={scroll ? 'bg-fff' : ''}>
                    <div className="wrapper">
                        <div className="logo"><Link to="/"><img src="/images/logo_1.png" alt="" className="myndl" /></Link></div>
                        <input type="radio" name="slider" id="menu-btn" />
                        <input type="radio" name="slider" id="close-btn" />
                        <ul className="nav-links">
                            <label htmlFor="close-btn" className="btn close-btn"><i className="fas fa-times"></i></label>
                            <li>
                                <Link to="/" className="desktop-item navbt">Buy <i className="fas fa-angle-down"></i>
                                </Link>
                                <input type="checkbox" id="showDrop2" />
                                <label htmlFor="showDrop2" className="mobile-item">Buy <i className="fas fa-angle-down"></i></label>
                                <ul className="drop-menu">
                                    {/* {
                                        projectStore.data.map((x, i) => {
                                            let slug = x.name.toLowerCase().replace(/\s+/g, '-');
                                            slug = slug.replace(/[^\w\-]+/g, '');
                                            return (
                                                <li key={i}><Link to={`/property?projectId=${x.id}`}>{x.name}</Link></li>
                                            )
                                        })
                                    } */}
                                    <li><Link to="/property?status=residential">Residential</Link></li>
                                    <li><Link to="/property?status=commercial">Commercial</Link></li>
                                    <li><Link to="/property?status=upcoming">Upcoming</Link></li>
                                    <li><Link to="/property?status=under-construction">Under Construction</Link></li>
                                    <li><Link to="/property?status=ready-to-move">Ready To Move</Link></li>
                                </ul>
                            </li>
                            {/* <li><Link to="/listing">Properties</Link></li> */}
                            <li><Link to="/about">About Us</Link></li>
                            <li><Link to="/contact">Contact Us</Link></li>
                            <li><Link to="/news">News</Link></li>
                            <li><Link to="/careers">Career</Link></li>
                            <li><Button onClick={() => dispatch(showModal())} className="dntebtn btn_yl remove_overlay"><i className="fad fa-comments"></i> Get In Touch</Button></li>
                        </ul>
                        <label htmlFor="menu-btn" className="btn menu-btn"><i className="fas fa-bars"></i></label>
                    </div>
                </nav>
            </div>
        </>
    )
}

export default TopNavbaar