import axios from "../../axios";
import { FETCH_FEATURED_MEMBER_SUCCESSFULL, FETCH_MEMBER_REQUEST, FETCH_MEMBER_SUCCESSFULL } from "../constants/constant"

export const getFeaturedMembers = () => async (dispatch) => {
    try {
        dispatch({ type: FETCH_MEMBER_REQUEST })
        let response = await axios.get('/users/list?feature_member=1');
        dispatch({
            type: FETCH_FEATURED_MEMBER_SUCCESSFULL,
            payload: response.data
        });
    }
    catch (error) {
        console.log({ error })
    }
}

export const getAllMembers = () => async (dispatch) => {
    try {
        dispatch({ type: FETCH_MEMBER_REQUEST })
        let response = await axios.get('/users/list?active=1');
        dispatch({
            type: FETCH_MEMBER_SUCCESSFULL,
            payload: response.data
        });
    }
    catch (error) {
        console.log({ error })
    }
}
