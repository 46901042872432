import { SHOW_MODAL, HIDE_MODAL } from "../constants/constant"

export const showModal = () => async (dispatch) => {
    try {
        dispatch({ type: SHOW_MODAL })
    }
    catch (error) {
        console.log({ error })
    }
}
export const hideModal = () => async (dispatch) => {
    try {
        dispatch({ type: HIDE_MODAL })
    }
    catch (error) {
        console.log({ error })
    }
}
