import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';

const NewsHome = () => {
  const newsStore = useSelector(state => state.newsState)
  return (
    <div className='news container'>
      <h2 className='section_heading'>
        <span>
          Latest News
        </span>
        <div className="heading_line"></div>

      </h2>
      <div className="d-flex">
        <Row className='g-4'>
          {
            newsStore.latest.map((item, i) => (
              <Col lg="6" md="12" sm="12" key={i}>
                <Link to={`/news/${item.slug}`} className="news_box">
                  <div className="img_box">
                    <img src={"https://elbrusinfratech.com/api/file/preview?filename=" + item?.img_src} alt={item.title} loading='lazy' />
                  </div>
                  <div className='news_cont'>
                    <h3 className='hover_primary_color'>
                      {item.title}
                    </h3>
                    <p className="desc">
                      {item.short_content}
                    </p>
                    <p className="date">{moment(item.created_at).format('MMMM DD, YYYY')}</p>
                  </div>
                </Link>
              </Col>
            ))
          }
        </Row>
      </div >
    </div >
  )
}

export default NewsHome