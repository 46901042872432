import { FETCH_FEATURED_PROPERTY_SUCCESSFULL, FETCH_PROPERTY_DETAIL_SUCCESSFULL, FETCH_PROPERTY_REQUEST, FETCH_PROPERTY_SUCCESSFULL, SET_FILTER_FORM } from "../constants/constant"
const initialState = {
    loading: true,
    data: [],
    featured: [],
    propertyDetail: null,
    filterForm: {
        min_price: null,
        max_price: null,
        type: null,
        location: null,
        min_area: null,
        max_area: null
    }

}
export const propertyReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PROPERTY_REQUEST:
            return { ...state, loading: true }
        case FETCH_PROPERTY_SUCCESSFULL:
            return { ...state, loading: false, data: action.payload }
        case FETCH_FEATURED_PROPERTY_SUCCESSFULL:
            return { ...state, loading: false, featured: action.payload }
        case FETCH_PROPERTY_DETAIL_SUCCESSFULL:
            return { ...state, loading: false, propertyDetail: action.payload }
        case SET_FILTER_FORM:
            return { ...state, loading: false, filterForm: action.payload }
        default:
            return state
    }
}