import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { showModal } from '../redux/actions/queryFormAction';

const Cta = () => {
    const dispatch = useDispatch()
    const handleShow = () => dispatch(showModal());

    return (
        <div className='cta'>
            <div className="container">
                <div className="cta_main">
                    <h2>Find Out What Our Experts Can Do For You</h2>
                    <p>
                        We are extremely happy to find you with us; Elbrus Infratech is one of top real estate companies in India. Contact us for your entire luxury homes, flats/apartments, and Commercial offices, shops requirements in Gurgaon!
                    </p>
                    <div className='consult-btn' onClick={handleShow}>GET A CONSULTATION</div>
                </div>
            </div>
        </div>
    )
}

export default Cta