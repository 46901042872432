import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getLocationList } from '../redux/actions/locationAtion';
import { getProjectList } from '../redux/actions/projectAction';
import { getPropertyTypeList } from '../redux/actions/propertyTypeAtion';
import { useState } from 'react';
import { setFilterFormStore } from '../redux/actions/propertyAction';
import { FormGroup, FormLabel } from 'react-bootstrap';
import Select from 'react-select';


const Banner = () => {
    const homeContentStore = useSelector(state => state.homeContent)
    const locationStore = useSelector(state => state.locationList)
    const projectStore = useSelector(state => state.projectList)
    const propertyTypeStore = useSelector(state => state.propertyTypeList)

    const defaultFilterForm = {
        min_price: null,
        max_price: null,
        type: null,
        location: null,
        project: null,
        min_area: null,
        max_area: null
    }
    const [filterForm, setFilterForm] = useState(defaultFilterForm)

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
        fade: true
    };

    const filterProperty = () => {
        let propertyRoute = '/property'
        let queryParams = {}
        console.log({ filterForm })
        dispatch(setFilterFormStore(filterForm))
        if (filterForm?.project) {
            queryParams.projectId = filterForm?.project?.value
        }
        if (filterForm?.location) {
            queryParams.locationId = filterForm?.location?.value
        }
        if (filterForm?.type) {
            queryParams.type_id = filterForm?.type?.value
        }
        if (filterForm?.project || filterForm?.location || filterForm?.type) {
            propertyRoute += '?' + new URLSearchParams(queryParams).toString()
        }
        console.log({ propertyRoute })
        navigate(propertyRoute)
    }


    useEffect(() => {
        if (!locationStore.data.length) {
            dispatch(getLocationList())
        }
        if (!projectStore.data.length) {
            dispatch(getProjectList())
        }
        if (!propertyTypeStore.data.length) {
            dispatch(getPropertyTypeList())
        }
    }, [dispatch])

    const selectCustomStyle = {
        control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? '#f15c5e' : '#dee2e6',
            boxShadow: state.isFocused ? '#f15c5e' : '#dee2e6',
            '&:hover': {
                borderColor: state.isFocused ? '#f15c5e' : '#dee2e6',
                boxShadow: state.isFocused ? '#f15c5e' : '#dee2e6',
            },
        })
    }


    return (
        <div className='banner_box'>
            <div className='slider_box'>
                <Slider {...settings}>
                    {
                        homeContentStore?.data?.banner_images ? (
                            homeContentStore?.data?.banner_images.map((x, i) => (
                                <div key={i}>
                                    <img src={"https://elbrusinfratech.com/api/file/preview?filename=" + x.img_src} className='banner_img' loading='lazy' />
                                </div>
                            ))
                        ) : (
                            <>
                                <div>
                                    <img src='./images/banner2.webp' className='banner_img' />
                                </div>
                                <div>
                                    <img src='./images/banner3.webp' className='banner_img' />
                                </div>
                            </>
                        )
                    }
                </Slider>
            </div>
            <div className="bnr_cnt">
                <h1>
                    {homeContentStore?.data?.banner_heading} <span className='secondary_color'>{homeContentStore?.data?.banner_sub_heading}</span>
                    {/* with <span className='secondary_color'> no commission</span> */}
                </h1>

                <div className='search_bar_container'>
                    <div className="search_bar_wrapper">
                        <FormGroup className='custom_form_group'>
                            <FormLabel>Location*</FormLabel>
                            <div className="select_picker flex-grow-1">
                                <Select
                                    defaultValue={null}
                                    isSearchable
                                    placeholder={"Select Locaton"}
                                    isClearable={false}
                                    value={filterForm.location}
                                    name="selectedLocation"
                                    onChange={e => setFilterForm({ ...filterForm, location: e })}
                                    styles={{ ...selectCustomStyle, menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    options={locationStore.data.map(item => ({ label: item.name, value: item.id }))}
                                    menuPortalTarget={document.body}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup className='custom_form_group  ms-lg-1'>
                            <FormLabel>Project</FormLabel>
                            <div className="select_picker flex-grow-1">
                                <Select
                                    defaultValue={null}
                                    placeholder="Select Project"
                                    isSearchable
                                    name="selectedProject"
                                    value={filterForm.project}
                                    isClearable={false}
                                    onChange={e => setFilterForm({ ...filterForm, project: e })}
                                    styles={{ ...selectCustomStyle, menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    options={projectStore.data.map(item => ({ label: item.name, value: item.id }))}
                                    id="disable-close-on-select"
                                    menuPortalTarget={document.body}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup className='custom_form_group  ms-lg-1'>
                            <FormLabel>Type</FormLabel>
                            <div className="select_picker flex-grow-1">
                                <Select
                                    defaultValue={null}
                                    placeholder="Select Type"
                                    isSearchable
                                    name="selectedProject"
                                    value={filterForm.type}
                                    isClearable={false}
                                    onChange={e => setFilterForm({ ...filterForm, type: e })}
                                    options={propertyTypeStore.data.map(item => ({ label: item.name, value: item.id }))}
                                    id="disable-close-on-select"
                                    menuPortalTarget={document.body}
                                    styles={{ ...selectCustomStyle, menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                        </FormGroup>
                    </div>
                    <div className="ms-3 proceed_search">
                        <button className="btn_yl" onClick={filterProperty}>
                            GO
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner