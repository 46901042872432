import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useParams } from 'react-router-dom'
import { getDeveloperDetail } from '../redux/actions/developerAction'
import { Card, Col, Row } from 'react-bootstrap'
import ListBanner from '../components/ListBanner'

const DevelopersProject = () => {
    const dispatch = useDispatch(),
        { developerDetail } = useSelector(state => state.developersState)
    const { developerId, projectType } = useParams()
    useEffect(() => {
        dispatch(getDeveloperDetail(developerId, projectType))
    }, [dispatch, developerId])
    const { pathname } = useLocation();
    const pageroute = pathname.substring(pathname.lastIndexOf('/') + 1);

    console.log(developerDetail, "Fsd")
    return (
        <div className='developer_project'>
            <ListBanner pagename={pageroute} developer={developerDetail?.title} />
            <div className="container dvlpr_cont">
                {
                    developerDetail?.projects && developerDetail?.projects.length ? (
                        <Row className=''>
                            {
                                developerDetail?.projects.map((x, i) => (
                                    <Col lg="3" md={4} sm={6} className="mt-4">
                                        <Link to={`/property?projectId=${x.id}`} className='property_card'>
                                            <img src={"https://elbrusinfratech.com/api/file/preview?filename=" + x?.image_src} alt={x?.name} loading='lazy' />
                                            <h4>{x?.name}</h4>
                                        </Link>
                                    </Col>
                                ))
                            }
                        </Row>
                    ) : (
                        <p>No Data Found</p >
                    )
                }
            </div>
        </div>
    )
}

export default DevelopersProject