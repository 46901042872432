import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './screens/Home';
import AboutUs from './screens/AboutUs';
import ContactUs from './screens/ContactUs';
import Layout from './common/Layout';
import Detailpage from './screens/Detailpage';
import Listing from './screens/Listing';
import News from './screens/News';
import Careers from './screens/Careers';
import NewsInner from './screens/NewsInner';
import CareerDetail from './screens/CareerDetail';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NotFound from './screens/NotFound';
import DeveloperDetail from './screens/DeveloperDetail';
import DevelopersProject from './screens/DevelopersProject';
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/detail/:id" element={<Detailpage />} />
          <Route path="/property" element={<Listing />} />
          <Route path="/developer/:developerId" element={<DeveloperDetail />} />
          <Route path="/developer/:developerId/:projectType" element={<DevelopersProject />} />
          <Route path="/news" element={<News />} />
          <Route path="/news/:articleSlug" element={<NewsInner />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/careers/:id" element={<CareerDetail />} />

          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </Router>
  );
}

export default App;
