import { FETCH_LOCATION_REQUEST, FETCH_LOCATION_SUCCESSFULL } from "../constants/constant"
const initialState = {
    loading: true,
    data: []

}
export const locationReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_LOCATION_REQUEST:
            return { ...state, loading: true }
        case FETCH_LOCATION_SUCCESSFULL:
            return { ...state, loading: false, data: action.payload }
        default:
            return state
    }
}