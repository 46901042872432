import React, { useEffect } from 'react'
import TopNavbaar from './TopNavbaar'
import Footer from './Footer'
import { Outlet, useLocation } from 'react-router-dom'
import QueryForm from '../components/QueryForm'
import { useDispatch } from 'react-redux'
import { showModal } from '../redux/actions/queryFormAction'
import moment from 'moment'

const Layout = () => {
    const location = useLocation();
    const dispatch = useDispatch()
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    useEffect(() => {

        const timeoutId = setTimeout(() => {
            if (localStorage.getItem("visit_at")) {
                if (moment(localStorage.getItem("visit_at")).add(1, 'hour').format('YYYY-MM-DD HH:mm:ss') < moment().format('YYYY-MM-DD HH:mm:ss')) {
                    dispatch(showModal())
                    localStorage.setItem('visit_at', moment().format('YYYY-MM-DD HH:mm:ss'))
                }
            } else {
                dispatch(showModal())
                localStorage.setItem('visit_at', moment().format('YYYY-MM-DD HH:mm:ss'))
            }
        }, 30000);
        return () => clearTimeout(timeoutId);
    }, [])
    return (
        <>
            <TopNavbaar />
            <Outlet />
            <Footer />
            <QueryForm />
        </>
    )
}

export default Layout