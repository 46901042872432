export const FETCH_CATEGORY_REQUEST = 'FETCH_CATEGORY_REQUEST';
export const FETCH_CATEGORY_SUCCESSFULL = 'FETCH_CATEGORY_SUCCESSFULL';

export const FETCH_LOCATION_REQUEST = 'FETCH_LOCATION_REQUEST';
export const FETCH_LOCATION_SUCCESSFULL = 'FETCH_LOCATION_SUCCESSFULL';

export const FETCH_PROPERTY_TYPE_REQUEST = 'FETCH_PROPERTY_TYPE_REQUEST';
export const FETCH_PROPERTY_TYPE_SUCCESSFULL = 'FETCH_PROPERTY_TYPE_SUCCESSFULL';


export const FETCH_HOME_CONTENT_REQUEST = 'FETCH_HOME_CONTENT_REQUEST';
export const FETCH_HOME_CONTENT_SUCCESSFULL = 'FETCH_HOME_CONTENT_SUCCESSFULL';

export const FETCH_ABOUT_CONTENT_REQUEST = 'FETCH_ABOUT_CONTENT_REQUEST';
export const FETCH_ABOUT_CONTENT_SUCCESSFULL = 'FETCH_ABOUT_CONTENT_SUCCESSFULL';

export const FETCH_COMPANY_DETAILS_REQUEST = 'FETCH_COMPANY_DETAILS_REQUEST';
export const FETCH_COMPANY_DETAILS_SUCCESSFULL = 'FETCH_COMPANY_DETAILS_SUCCESSFULL';


export const FETCH_PROPERTY_REQUEST = 'FETCH_PROPERTY_REQUEST';
export const FETCH_PROPERTY_SUCCESSFULL = 'FETCH_PROPERTY_SUCCESSFULL';
export const FETCH_PROPERTY_DETAIL_SUCCESSFULL = 'FETCH_PROPERTY_DETAIL_SUCCESSFULL';
export const FETCH_FEATURED_PROPERTY_SUCCESSFULL = 'FETCH_FEATURED_PROPERTY_SUCCESSFULL';
export const SET_FILTER_FORM = 'SET_FILTER_FORM';

export const FETCH_NEWS_REQUEST = 'FETCH_NEWS_REQUEST';
export const FETCH_NEWS_SUCCESSFULL = 'FETCH_NEWS_SUCCESSFULL';
export const FETCH_LATEST_NEWS_SUCCESSFULL = 'FETCH_LATEST_NEWS_SUCCESSFULL';
export const FETCH_SINGLE_NEWS_SUCCESSFULL = 'FETCH_SINGLE_NEWS_SUCCESSFULL';


export const FETCH_MEMBER_REQUEST = 'FETCH_MEMBER_REQUEST';
export const FETCH_MEMBER_SUCCESSFULL = 'FETCH_MEMBER_SUCCESSFULL';
export const FETCH_FEATURED_MEMBER_SUCCESSFULL = 'FETCH_FEATURED_MEMBER_SUCCESSFULL';

export const FETCH_TESTIMONIAL_REQUEST = 'FETCH_TESTIMONIAL_REQUEST';
export const FETCH_TESTIMONIAL_SUCCESSFULL = 'FETCH_TESTIMONIAL_SUCCESSFULL';


export const FETCH_CAREER_REQUEST = 'FETCH_CAREER_REQUEST';
export const FETCH_CAREER_SUCCESSFULL = 'FETCH_CAREER_SUCCESSFULL';
export const FETCH_SINGLE_CAREER_SUCCESSFULL = 'FETCH_SINGLE_CAREER_SUCCESSFULL';


export const FETCH_DEVELOPER_REQUEST = 'FETCH_DEVELOPER_REQUEST';
export const FETCH_DEVELOPER_SUCCESSFULL = 'FETCH_DEVELOPER_SUCCESSFULL';
export const FETCH_DEVELOPER_DETIAL_SUCCESSFULL = 'FETCH_DEVELOPER_DETIAL_SUCCESSFULL';


export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

