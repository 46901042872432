import { combineReducers, compose, applyMiddleware } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk';
import { projectReducer } from './reducers/projectReducer';
import { homeContentReducer } from './reducers/homeContentReducer';
import { propertyReducer } from './reducers/propertyReducer';
import { newsReducer } from './reducers/newsReducer';
import { membersReducer } from './reducers/membersReducer';
import { testimonialReducer } from './reducers/testimonialReducer';
import { aboutContentReducer } from './reducers/aboutContentReducer';
import { careerReducer } from './reducers/careerReducer';
import { locationReducer } from './reducers/locationReducer';
import { propertyTypeReducer } from './reducers/propertyTypeReducer';
import { companyDetailsReducer } from './reducers/companyDetailsReducer';
import { developersReducer } from './reducers/developersReducer';
import { queryFormReducer } from './reducers/queryFormReducer';


const initialState = {
}


const reducer = combineReducers({
    projectList: projectReducer,
    homeContent: homeContentReducer,
    aboutContent: aboutContentReducer,
    property: propertyReducer,
    newsState: newsReducer,
    memberState: membersReducer,
    testimonialState: testimonialReducer,
    careerState: careerReducer,
    locationList: locationReducer,
    propertyTypeList: propertyTypeReducer,
    companyDetails: companyDetailsReducer,
    developersState: developersReducer,
    queryFormState: queryFormReducer,
})
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
export const store = configureStore({
    reducer,
}, initialState,
    composeEnhancer(applyMiddleware(thunk)))
export default store;





