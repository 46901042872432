import React, { useEffect } from 'react'
import ListBanner from '../components/ListBanner'
import Newscard from '../components/Newscard'
import { useDispatch, useSelector } from 'react-redux'
import { getAllNews } from '../redux/actions/newsAction'
import { useState } from 'react'

const News = () => {
    const newsStore = useSelector(state => state.newsState)
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await dispatch(getAllNews())
            setLoading(false);
        }
        fetchData()
    }, [dispatch])

    return (
        <>
            {
                loading ? (
                    <div className="loader_wrapper">
                        <div id="loader"></div>
                    </div>
                ) : (
                    <div className='news'>
                        <ListBanner pagename="News" />
                        <div className="container nws">
                            <h2 className='section_heading mb-4'>
                                <span>
                                    REAL ESTATE NEWS & UPDATES
                                </span>
                                <div className="heading_line"></div>
                            </h2>
                            <div className="row">
                                {
                                    newsStore.data.map((item, i) => (
                                        <div className="col-lg-4 col-md-6 col-sm-12 mt-4" key={i}>
                                            <Newscard data={item} />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default News