import { FETCH_CATEGORY_REQUEST, FETCH_CATEGORY_SUCCESSFULL } from "../constants/constant"
const initialState = {
    loading: true,
    data: []

}
export const projectReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CATEGORY_REQUEST:
            return { ...state, loading: true }
        case FETCH_CATEGORY_SUCCESSFULL:
            return { ...state, loading: false, data: action.payload }
        default:
            return state
    }
}