import React from 'react'
import CountUp from 'react-countup';
import { useSelector } from 'react-redux';

const Choose = () => {
    const aboutContentStore = useSelector(state => state.aboutContent)
    return (
        <div className='choose'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12 mt-4">
                        <div className="choose_cnt pe-lg-5">
                            <h2>{aboutContentStore.data?.second_heading}
                            </h2>
                            <h3>
                                {aboutContentStore.data?.second_sub_heading}
                            </h3>
                            <p dangerouslySetInnerHTML={{
                                __html: aboutContentStore.data?.second_main_text
                            }}>
                            </p>
                            <div className="d-flex flex-wrap justify-content-between">
                                <div className="conter">
                                    <i className="fal fa-building"></i>
                                    <h3>
                                        <CountUp scrollSpyOnce={true} end={aboutContentStore.data?.years_experience || 0} enableScrollSpy={true} />+
                                    </h3>
                                    <p>
                                        Years of Experience
                                    </p>
                                </div>
                                <div className="conter">
                                    <i className='fad fa-thumbs-up'></i>
                                    <h3>
                                        <CountUp scrollSpyOnce={true} end={aboutContentStore.data?.happy_customer || 0} enableScrollSpy={true} />+

                                    </h3>
                                    <p>
                                        Happy Customers
                                    </p>
                                </div>
                                <div className="conter">
                                    <i className='fad fa-user'></i>
                                    <h3>
                                        <CountUp scrollSpyOnce={true} end={aboutContentStore.data?.channel_partners || 0} enableScrollSpy={true} />+

                                    </h3>
                                    <p>
                                        Channel Partners
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 mt-4">
                        <div className="d-flex flex-wrap rytc">
                            <div className="rytcnt bg_primary">
                                <i className="fad fa-car-building"></i>
                                <h4>{aboutContentStore.data?.service_heading_1}</h4>
                                <p>{aboutContentStore.data?.service_text_1}</p>
                            </div>
                            <div className="rytcnt">
                                <i className="fad fa-user-headset"></i>
                                <h4>{aboutContentStore.data?.service_heading_2}</h4>
                                <p>{aboutContentStore.data?.service_text_2}</p>
                            </div>
                            <div className="rytcnt">
                                <i className='fad fa-rupee-sign'></i>
                                <h4>{aboutContentStore.data?.service_heading_3}</h4>
                                <p>{aboutContentStore.data?.service_text_3}</p>
                            </div>
                            <div className="rytcnt bg_primary">
                                <i className='fad fa-handshake'></i>
                                <h4>{aboutContentStore.data?.service_heading_4}</h4>
                                <p>{aboutContentStore.data?.service_text_4}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Choose