import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import axios from '../axios';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal } from '../redux/actions/queryFormAction';
import { toast } from 'react-toastify';

const QueryForm = () => {
    const { show } = useSelector(state => state.queryFormState)
    const dispatch = useDispatch()
    const handleClose = () => dispatch(hideModal())
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contact: '',
        message: ''
    })
    const handleSubmit = (e) => {
        e.preventDefault()
        console.log({ formData })
        axios.post('/contact/create', formData)
        setFormData({
            name: '',
            email: '',
            contact: '',
            message: ''
        })
        toast.success('Form Submit')
        handleClose()
    }
    return (
        <Modal show={show} onHide={handleClose} centered className='ctamodal'>
            <Modal.Body>
                <div className="cntfrm">
                    <img src="/images/logo_1.png" alt="" loading='lazy' />
                    <h3>Write To Us</h3>
                    <form onSubmit={handleSubmit}>
                        <input type="text" value={formData.name} onChange={e => setFormData({ ...formData, name: e.target.value })} placeholder='Enter Your Name*' required />
                        <input type="email" value={formData.email} onChange={e => setFormData({ ...formData, email: e.target.value })} placeholder='Enter Your Email Address*' required />
                        <input type="text" value={formData.contact} onChange={e => setFormData({ ...formData, contact: e.target.value })} placeholder='Enter Your Phone Number' required />
                        <textarea name="" id="" value={formData.message} onChange={e => setFormData({ ...formData, message: e.target.value })} placeholder='Message' required></textarea>
                        <button className="btn_yl mx-auto mt-2 d-flex" type='submit'>Send Message</button>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default QueryForm