import axios from "../../axios";
import { FETCH_CATEGORY_REQUEST, FETCH_CATEGORY_SUCCESSFULL } from "../constants/constant"

export const getProjectList = () => async (dispatch) => {
    try {
        dispatch({ type: FETCH_CATEGORY_REQUEST })
        let response = await axios.get('/developers/project');
        dispatch({
            type: FETCH_CATEGORY_SUCCESSFULL,
            payload: response.data
        });
    }
    catch (error) {
        console.log({ error })
    }
}
